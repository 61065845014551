<template>
  <CDataTable
    :table-filter="false"
    :hover="hover"
    :striped="striped"
    :border="border"
    :small="small"
    :fixed="fixed"
    :items="items"
    :fields="fields"
    sorter
    :items-per-page="small ? 5 : 10"
    :dark="dark"
    pagination
  >

  <template #toggle-header :v-if="false">
  </template>
  <template #toggle="{item}">
    <div v-if="showToggle"><CSwitch class="mx-1" color="primary" checked shape="pill"/>{{item.enabled}}</div>
    <div v-else> </div>
  </template>
  <template #status="{item}">
    

          <td>
            <CBadge :color="showStatus(item.status)">{{item.status}}</CBadge>
          </td>
        </template>

        <template #show_details="{item, index}">
        <td class="py-2">
          <CButton
            color="secondary"
            variant="outline"
            square
            size="sm"
            @click="toggleDetails(item, index)"
          >
            {{Boolean(item._toggled) ? 'less' : '>'}}
          </CButton>
        </td>
      </template>
      <template #details="{ item, index }">
         <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
           <slot name="details" v-bind:item="item">
           <CCard>
             Hello World My name is {{item.name}}
           </CCard>
           </slot>
         </CCollapse>
         
      </template>
  </CDataTable>
</template>



<script>

export default {
  name: "DeviceManagementGrid",
  components: {
    
  },
  props: {
    items: Array,
    fields: {
      type: Array,
      default() {
        return ["device", "branch", {key: "truck", label:"Name"},"radio", {key: "status", label:"State"}, {key:"show_details",label:"Details"}];
        //return [{key: "toggle", label: "Action"}, "device", "branch", {key: "truck", label:"Name"},"radio", {key: "status", label:"State"}, {key:"show_details",label:"Details"}];
      }
    },
    caption: {
      type: String,
      default: "Table",
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    showToggle: Boolean,
  },
  data(){
    return{
      details: [],
      collapseDuration: 300
    }
  },
  methods: {
    toggleDetails (item, index) {
      this.$emit("clickDetails",item, index);
    },
    showStatus(status,index){
      switch(status){
        case 'active':return 'success'
        case 'inactive': return 'secondary'
        case 'idle': return 'warning'
        default: return 'danger'
      }
    }
  },
};
</script>