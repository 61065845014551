<template>
  <div>
    <div class="overflow-none m-1 p-1" >
        <CRow v-if="info">
          <CCol>
            <span class="cards-label card-text-light data-heading">IMEI</span>
            <br />
            <span class="cards-text">{{ info.imei }}</span>

            <span class="cards-label card-text-light data-heading">Sensors</span>
            <br />
            <span class="cards-text-dark">{{ getSensors(info) }}</span>
          </CCol>
          <CCol v-if="info">
            <div v-if="info.radio">
              <span class="cards-label card-text-light data-heading"
                >Receiver Type</span
              ><br />
              <span class="cards-text card-text-dark"
                >{{ info.radio }}</span
              >
            </div>

           

            <span class="cards-label card-text-light data-heading"
              >Device Name <br
            /></span>
            <span class="cards-text">{{ info.name }}</span>
          </CCol>
          <slot name="append">
          </slot>

        </CRow>

        <CRow> </CRow>
     </div>
  </div>
</template>


<script>
export default {
  name: "DeviceDetails",
  components: {},
  props: {
    info: {
      type: Object,
    },
    
  },
  data() {
    return {};
  },
  methods: {
    onClick(e) {},
    getSensors(info) {
      return info.sensors[0].sensor;
    },
    showTime(time) {
      if (!time) return "";
      var myTime;
      try {
        myTime = this.$moment.duration(Date.now() - new Date(time)).humanize();
        myTime += " ago";
      } catch (e) {
        console.log(e);
        if (time) myTime = time.toString();
      } finally {
        return myTime;
      }
    },
  },
};
</script>