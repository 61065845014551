

<template>
  <div>
    
      <CRow v-if="info">
        <CCol>
          <span class="cards-label card-text-light">IoT Device ID</span>
          <span class=" cards-text ">{{ info.device }}</span>
          <span class="cards-label card-text-light">IMEI</span>
          <span class="cards-text">{{ info.imei }}</span>
          <span class="cards-label card-text-light">Last Connected</span>
          <span id="last-connected" class="cards-text">{{
            showTime(lastConnected)
          }}</span>
        </CCol>
        <CCol v-if="info">
          <div v-if="info.radio">
         <span class="cards-label card-text-light" >Receiver Type</span>
          <span class="cards-text">{{ info.radio }}</span>
          </div>

          <span class="cards-label card-text-light" v-if="false">Truck Status</span>
          <span class="cards-text"  v-if="false" v-html="getStatus()"></span>
          <span class="cards-label card-text-light">Device Name <br></span>
          <span class="cards-text">{{name}}</span>
        </CCol>
      </CRow>
    
  </div>
</template>



<style scoped>
</style>


<script>


export default {
  name: "IotInfo",
  components: {
    //DashboardCard,
  },
  props: {
    info: {
      type: Object,
    },
  },

  data() {
    return {
      //: {},
      //lastConnected: Date.now(),
    };
  },
  computed:{
    lastConnected: function(){
      if(this.info && this.info.status)
      {
        //return this.showTime(this.info.status.lastConnect);
       return this.info.status.lastConnect;
      }
        else return ""; 
      
    },
    name :function(){
      if(this.info)
      {
        return this.info.truck || this.info.name;
      }
    }
  },
  methods: {
    showTime(time) {
      if (!time) return "";
      var myTime;
        try {
            myTime = this.$moment.duration(Date.now()-new Date(time)).humanize();
            myTime += " ago";
        } catch (e) {
            console.log(e);
            if(time)
              myTime = time.toString();
        } finally {
          return myTime;
        }
      },
    getStatus() {
      return "";
      if (!this.info) {
        return `<span class="warning"> uknown</span>`;
      } else {
        switch (info.status) {
          default:
            return `<span class="warning"> uknown</span>`;
        }
      }
    },
  },
};
</script>