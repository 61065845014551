var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "overflow-none m-1 p-1" },
      [
        _vm.info
          ? _c(
              "CRow",
              [
                _c("CCol", [
                  _c(
                    "span",
                    { staticClass: "cards-label card-text-light data-heading" },
                    [_vm._v("IMEI")]
                  ),
                  _c("br"),
                  _c("span", { staticClass: "cards-text" }, [
                    _vm._v(_vm._s(_vm.info.imei))
                  ]),
                  _c(
                    "span",
                    { staticClass: "cards-label card-text-light data-heading" },
                    [_vm._v("Sensors")]
                  ),
                  _c("br"),
                  _c("span", { staticClass: "cards-text-dark" }, [
                    _vm._v(_vm._s(_vm.getSensors(_vm.info)))
                  ])
                ]),
                _vm.info
                  ? _c("CCol", [
                      _vm.info.radio
                        ? _c("div", [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "cards-label card-text-light data-heading"
                              },
                              [_vm._v("Receiver Type")]
                            ),
                            _c("br"),
                            _c(
                              "span",
                              { staticClass: "cards-text card-text-dark" },
                              [_vm._v(_vm._s(_vm.info.radio))]
                            )
                          ])
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          staticClass:
                            "cards-label card-text-light data-heading"
                        },
                        [_vm._v("Device Name "), _c("br")]
                      ),
                      _c("span", { staticClass: "cards-text" }, [
                        _vm._v(_vm._s(_vm.info.name))
                      ])
                    ])
                  : _vm._e(),
                _vm._t("append")
              ],
              2
            )
          : _vm._e(),
        _c("CRow")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }