var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CDataTable", {
    attrs: {
      "table-filter": false,
      hover: _vm.hover,
      striped: _vm.striped,
      border: _vm.border,
      small: _vm.small,
      fixed: _vm.fixed,
      items: _vm.items,
      fields: _vm.fields,
      sorter: "",
      "items-per-page": _vm.small ? 5 : 10,
      dark: _vm.dark,
      pagination: ""
    },
    scopedSlots: _vm._u(
      [
        {
          key: "toggle-header",
          fn: function() {
            return undefined
          },
          proxy: true
        },
        {
          key: "toggle",
          fn: function(ref) {
            var item = ref.item
            return [
              _vm.showToggle
                ? _c(
                    "div",
                    [
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: { color: "primary", checked: "", shape: "pill" }
                      }),
                      _vm._v(_vm._s(item.enabled))
                    ],
                    1
                  )
                : _c("div")
            ]
          }
        },
        {
          key: "status",
          fn: function(ref) {
            var item = ref.item
            return [
              _c(
                "td",
                [
                  _c(
                    "CBadge",
                    { attrs: { color: _vm.showStatus(item.status) } },
                    [_vm._v(_vm._s(item.status))]
                  )
                ],
                1
              )
            ]
          }
        },
        {
          key: "show_details",
          fn: function(ref) {
            var item = ref.item
            var index = ref.index
            return [
              _c(
                "td",
                { staticClass: "py-2" },
                [
                  _c(
                    "CButton",
                    {
                      attrs: {
                        color: "secondary",
                        variant: "outline",
                        square: "",
                        size: "sm"
                      },
                      on: {
                        click: function($event) {
                          return _vm.toggleDetails(item, index)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(Boolean(item._toggled) ? "less" : ">") +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          }
        },
        {
          key: "details",
          fn: function(ref) {
            var item = ref.item
            var index = ref.index
            return [
              _c(
                "CCollapse",
                {
                  attrs: {
                    show: Boolean(item._toggled),
                    duration: _vm.collapseDuration
                  }
                },
                [
                  _vm._t(
                    "details",
                    [
                      _c("CCard", [
                        _vm._v(
                          " Hello World My name is " + _vm._s(item.name) + " "
                        )
                      ])
                    ],
                    { item: item }
                  )
                ],
                2
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }